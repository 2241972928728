@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

body{
 margin: 0px;
}
/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 6px; /* Width of the vertical scrollbar */
  height: 7px; /* Height of the horizontal scrollbar */
}

::-webkit-scrollbar-thumb {
  background: #ababab; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 2px solid transparent; /* Transparent border */
  background-clip: padding-box; /* Makes the border visible */
}

::-webkit-scrollbar-thumb:hover {
  background: #919191; /* Darker shade on hover */
  border: 0; /* Remove border on hover */
}

::-webkit-scrollbar-track {
  background: transparent; /* Transparent track background */
  border-radius: 10px; /* Rounded corners for the track */
}
