@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

::-webkit-scrollbar {
  width: 6px; 
  height: 7px; 
}

::-webkit-scrollbar-thumb {
  background: #ababab; 
  border-radius: 10px; 
  border: 1.5px solid transparent; 
  background-clip: padding-box; 
}

::-webkit-scrollbar-thumb:hover {
  background: #919191; 
  border: 0; 
}

::-webkit-scrollbar-track {
  background: transparent; 
  border-radius: 5px; 
}